<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <!-- Email Settings -->

    <div class="mx-auto mt-3 lg:mt-10 md:mt-5 max-w-6xl" v-if="isLoaded">
      <div>
        <div>
          <div class="font-bold text-22px text-oCharcoal">
            <i class="fas fa-mobile-alt text-22px text-oPurple"></i>
            <span class="pl-3">{{
              $t(
                'components.settingsManagement.riderApp.headline.pushNotification'
              )
            }}</span>
          </div>
        </div>
      </div>
      <div :key="`pushNotification_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in pushNotificationSettings">
          <SmartAppInput
            :key="itemIndex"
            :rowId="`pushNotification_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            :options="item.options"
            v-model="riderApp[`${item.variable}`]"
            @save="onSave('riderApp', `${item.variable}`, $event)"
          />
        </template>
      </div>
      <div class="flex justify-between items-center">
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-mobile-alt text-22px text-oPurple"></i>
          <span class="pl-3">{{
            $t('components.settingsManagement.riderApp.headline.basicSettings')
          }}</span>
        </div>
      </div>
      <div :key="`basic_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in basicSettings">
          <SmartAppInput
            :key="itemIndex"
            :rowId="`basic_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            :options="item.options"
            v-model="basic[`${item.variable}`]"
            @save="onSave('basic', `${item.variable}`, $event)"
          />
        </template>
      </div>

      <div>
        <div>
          <div class="font-bold text-22px text-oCharcoal">
            <i class="fas fa-mobile-alt text-22px text-oPurple"></i>
            <span class="pl-3">{{
              $t(
                'components.settingsManagement.riderApp.headline.appFeature.titile'
              )
            }}</span>
          </div>
          <p class="break-words title-description mt-1">
            {{
              $t(
                'components.settingsManagement.riderApp.headline.appFeature.description'
              )
            }}
          </p>
        </div>
      </div>
      <div :key="`appFeature_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in appFeatureSettings">
          <SmartToggleInput
            v-if="item.type === 'toggle'"
            :key="`appFeature_${itemIndex}`"
            :label="item.label"
            :description="item.description"
            :onText="item.onText"
            :offText="item.offText"
            :onValue="item.onValue"
            :offValue="item.offValue"
            v-model="riderApp[`${item.variable}`]"
            @save="onSave('riderApp', `${item.variable}`, $event)"
          />

          <template v-else>
            <SmartAppInput
              v-if="item.variable === 'co2_saved_unit'"
              :key="itemIndex"
              :rowId="`appFeature_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :options="getCO2SavedUnitChoices"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="riderApp[`${item.variable}`]"
              @save="onSave('riderApp', `${item.variable}`, $event)"
            />
            <SmartAppInput
              v-if="item.variable === 'weight_unit'"
              :key="itemIndex"
              :rowId="`appFeature_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :options="getRiderWeightUnitChoices"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="riderApp[`${item.variable}`]"
              @save="onSave('riderApp', `${item.variable}`, $event)"
            />
            <SmartAppInput
              v-if="item.variable === 'default_home_screen'"
              :key="itemIndex"
              :rowId="`appFeature_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :options="getDefaultHomeScreenChoices"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="riderApp[`${item.variable}`]"
              @save="onSave('riderApp', `${item.variable}`, $event)"
            />
          </template>
        </template>
      </div>
      <div>
        <div>
          <div class="font-bold text-22px text-oCharcoal">
            <i class="fas fa-mobile-alt text-22px text-oPurple"></i>
            <span class="pl-3">{{
              $t('components.settingsManagement.riderApp.headline.wallet')
            }}</span>
          </div>
        </div>
      </div>
      <div :key="`appWalletSettings_${counter}`" class=" mt-3 pt-5 border-t-2">
        <SmartAppInput
          :key="`appWalletSettings_${counter + 0}`"
          :rowId="`appWalletSettings_${0}`"
          :type="appWalletSettings[0].type"
          :label="appWalletSettings[0].label"
          :description="appWalletSettings[0].description"
          :isEdit="appWalletSettings[0].isEdit"
          v-model="riderApp[`${appWalletSettings[0].variable}`]"
          :options="getRiderWalletBalanceTypeChoices"
          @save="onSave('riderApp', `${appWalletSettings[0].variable}`, $event)"
        />
        <SmartToggleInput
          :key="`appWalletSettings_${counter + 1}`"
          :type="appWalletSettings[1].type"
          :label="appWalletSettings[1].label"
          :placeholder="appWalletSettings[1].placeholder"
          :description="appWalletSettings[1].description"
          :onText="appWalletSettings[1].onText"
          :offText="appWalletSettings[1].offText"
          :onValue="appWalletSettings[1].onValue"
          :offValue="appWalletSettings[1].offValue"
          v-model="riderApp[`${appWalletSettings[1].variable}`]"
          @save="onSave('riderApp', `${appWalletSettings[1].variable}`, $event)"
        />
        <SmartAppInput
          :key="`appWalletSettings_${counter + 2}`"
          :rowId="`appWalletSettings_${2}`"
          :type="appWalletSettings[2].type"
          :label="appWalletSettings[2].label"
          :description="appWalletSettings[2].description"
          :isEdit="appWalletSettings[2].isEdit"
          v-model="riderApp[`${appWalletSettings[2].variable}`]"
          :haveUnitText="appWalletSettings[2].haveUnitText"
          :unitText="appWalletSettings[2].unitText"
          @save="onSave('riderApp', `${appWalletSettings[2].variable}`, $event)"
        />
      </div>

      <div>
        <div>
          <div class="font-bold text-22px text-oCharcoal">
            <i class="fas fa-mobile-alt text-22px text-oPurple"></i>
            <span class="pl-3">{{
              $t(
                'components.settingsManagement.riderApp.headline.unlockScreenIllustration'
              )
            }}</span>
          </div>
        </div>
      </div>
      <div
        :key="`appInstructionSettings_${counter}`"
        class=" mt-3 pt-5 border-t-2"
      >
        <template
          v-for="(key, keyIndex) in Object.keys(appInstructionSettings)"
        >
          <SmartToggleInput
            :key="`appInstructionSettings_${keyIndex}`"
            :label="appInstructionSettings[key].title"
            :onText="`Yes`"
            :offText="`No`"
            :onValue="true"
            :offValue="false"
            :description="appInstructionSettings[key].description"
            v-model="appInstructionSettings[key].should_display"
            @save="onInstructionSave(appInstructionSettings[key].id, $event)"
          />
        </template>
      </div>

      <div>
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <i class="fas fa-mobile-alt text-22px text-oPurple"></i>
          <span class="pl-3">
            {{
              $t(
                'components.settingsManagement.riderApp.headline.popupScreenBeforeUnlockingVehicle'
              )
            }}
          </span>
          <img class="ml-3 w-7 h-7" src="@/assets/img/new.png" />
        </div>
      </div>
      <div
        :key="`popupScreenBeforeUnlockingVehicleSettings_${counter}`"
        class=" mt-3 pt-5 border-t-2"
      >
        <template
          v-for="(item, itemIndex) in popupScreenBeforeUnlockingVehicleSettings"
        >
          <SmartToggleInput
            v-if="item.type === 'toggle'"
            :key="`popupScreenBeforeUnlockingVehicleSettings_${itemIndex}`"
            :label="item.label"
            :description="item.description"
            :onText="item.onText"
            :offText="item.offText"
            :onValue="item.onValue"
            :offValue="item.offValue"
            v-model="riderApp[`${item.variable}`]"
            @save="onSave('riderApp', `${item.variable}`, $event)"
          />
        </template>
      </div>
      <div>
        <div>
          <div class="flex items-center  justify-between">
            <div class="font-bold text-22px text-oCharcoal flex">
              <span>
                {{
                  $t(
                    'components.settingsManagement.riderApp.headline.additionalMessages.title'
                  )
                }}
              </span>
              <div
                class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
                @click="onAddHardwareMessage"
              >
                <i class="fas fa-plus-circle" style="color: black"></i>
              </div>
              <img class="ml-3 w-7 h-7" src="@/assets/img/new.png" />
            </div>
          </div>
          <p class="text-xs  ">
            {{
              $t(
                'components.settingsManagement.riderApp.headline.additionalMessages.description'
              )
            }}
          </p>
        </div>
        <div
          :key="`messageSettings_${counter}`"
          class=" mt-5 pt-5 border-oLightestGray border-t-2"
        >
          <STable
            :sId-id="'message-list'"
            :headers="additionalMessageListHeaders"
          >
            <template v-for="(item, itemIndex) in additionalMessageList">
              <STableRow :key="itemIndex" text-fallback-always>
                <STableRowItem :text="item.message_type_display_text" />
                <STableRowItem :text="item.message_text" />
                <STableRowItem>
                  <div class="flex justify-start gap-2">
                    <OtoEditIcon @click="onEditHardwareMessage(item)" />
                    <div
                      class="cursor-pointer"
                      @click="onRemoveHardwareMessage(item.id)"
                    >
                      <i class="fas fa-trash"></i>
                    </div>
                  </div>
                </STableRowItem> </STableRow></template
          ></STable>
        </div>
        <AddEditHardwareMessage :messageTypes="additionalMessageTypes" />
      </div>
    </div>
  </section>
</template>
<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import SmartToggleInput from '@/components/form/SmartToggleInput.vue'
import AddEditHardwareMessage from '@/views/settings/hardware-message/AddEditHardwareMessage.vue'
import { STable, STableRow, STableRowItem } from '@/components/s-table'
import { useEndpoints } from '@/composables/api/useEndpoints'
import { HardwareMessageConfig } from '@/config/HardwareMessageConfig'
import { EventBus } from '@/utils'
export default {
  name: 'RiderAppSettings',
  components: {
    SmartAppInput,
    SmartToggleInput,
    STable,
    STableRow,
    STableRowItem,
    OtoEditIcon: () => import('@/components/ui/OtoEditIcon'),
    AddEditHardwareMessage,
  },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      co2SavedUnitChoices: [],
      riderWeightUnitChoices: [],
      riderWalletBalanceTypeChoices: [
        { text: 'Currency based wallet', value: 0 },
        { text: 'Riding Time based Wallet ', value: 1 },
      ],
      defaultHomeScreenChoices: [
        { text: 'Rent', value: 'RIDE' },
        { text: 'Long Rent', value: 'RENTAL' },
      ],
      currentSettingsGroup: null,
      basic: {
        privacy_policy_url: '',
        terms_of_service_url: '',
        terms_and_conditions_url: '',
        about_url: '',
        help_url: '',
        faq_url: '',
        facebook_link: '',
        instagram_link: '',
        twitter_link: '',
        helpline: '',
      },
      riderApp: {
        app_latest_version: null,
        app_min_version: null,
        soft_update_title: null,
        soft_update_description: null,
        hard_update_title: null,
        hard_update_description: null,
        vehicle_renting_enabled: null,
        pass_enabled: null,
        voucher_enabled: null,
        points_enabled: null,
        live_chat_enabled: null,
        co2_saved_unit: null,
        weight_unit: null,
        should_display_payment_source_selection_option: null,
        fcm_server_key: null,
        fcm_project_id: null,
        fcm_private_key_id: null,
        fcm_private_key: null,
        fcm_client_email: null,
        fcm_client_id: null,
        fcm_client_x509_cert_url: null,
        sun_pod_feature_enabled: null,
        should_rider_balance_expire: null,
        rider_balance_validity_period: null,
        rider_wallet_balance_type: null,
        default_home_screen: null,
      },
      pushNotificationSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmServerKey.title'
          ),
          placeholder: ``,
          description: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmServerKey.description'
          ),
          variable: 'fcm_server_key',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmProjectId.title'
          ),
          placeholder: ``,
          description: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmProjectId.description'
          ),
          variable: 'fcm_project_id',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmPrivateKeyId.title'
          ),
          placeholder: ``,
          description: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmPrivateKeyId.description'
          ),
          variable: 'fcm_private_key_id',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmPrivateKey.title'
          ),
          placeholder: ``,
          description: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmPrivateKey.description'
          ),
          variable: 'fcm_private_key',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmClientEmail.title'
          ),
          placeholder: ``,
          description: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmClientEmail.description'
          ),
          variable: 'fcm_client_email',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmClientId.title'
          ),
          placeholder: ``,
          description: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmClientId.description'
          ),
          variable: 'fcm_client_id',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmClientX509CertUrl.title'
          ),
          placeholder: ``,
          description: this.$t(
            'components.settingsManagement.riderApp.pushNotificationSettings.fcmClientX509CertUrl.description'
          ),
          variable: 'fcm_client_x509_cert_url',
          isEdit: false,
        },
      ],
      basicSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.basicSettings.privacyPolicyURL.title'
          ),
          placeholder: `e.g. https://www.example.com`,
          description: this.$t(
            'components.settingsManagement.riderApp.basicSettings.privacyPolicyURL.description'
          ),
          variable: 'privacy_policy_url',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.basicSettings.termsOfServiceURL.title'
          ),
          placeholder: `e.g. https://www.example.com`,
          description: this.$t(
            'components.settingsManagement.riderApp.basicSettings.termsOfServiceURL.description'
          ),
          variable: 'terms_of_service_url',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.basicSettings.termsAndConditionsURL.title'
          ),
          placeholder: `e.g. https://www.example.com`,
          description: this.$t(
            'components.settingsManagement.riderApp.basicSettings.termsAndConditionsURL.description'
          ),
          variable: 'terms_and_conditions_url',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.basicSettings.aboutLinkURL.title'
          ),
          placeholder: `e.g. https://www.example.com`,
          description: this.$t(
            'components.settingsManagement.riderApp.basicSettings.aboutLinkURL.description'
          ),
          variable: 'about_url',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.basicSettings.faqLinkURL.title'
          ),
          placeholder: `e.g. https://www.example.com`,
          description: this.$t(
            'components.settingsManagement.riderApp.basicSettings.faqLinkURL.description'
          ),
          variable: 'faq_url',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.basicSettings.helpLinkURL.title'
          ),
          placeholder: `e.g. https://www.example.com`,
          description: this.$t(
            'components.settingsManagement.riderApp.basicSettings.helpLinkURL.description'
          ),
          variable: 'help_url',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.basicSettings.facebookLinkURL.title'
          ),
          placeholder: `e.g. https://www.facebook.com/id`,
          description: this.$t(
            'components.settingsManagement.riderApp.basicSettings.facebookLinkURL.description'
          ),
          variable: 'facebook_link',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.basicSettings.instagramLinkURL.title'
          ),
          placeholder: `e.g. https://www.instagram.com/id`,
          description: this.$t(
            'components.settingsManagement.riderApp.basicSettings.instagramLinkURL.description'
          ),
          variable: 'instagram_link',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.basicSettings.twitterLinkURL.title'
          ),
          placeholder: `e.g. https://www.twitter.com/id`,
          description: this.$t(
            'components.settingsManagement.riderApp.basicSettings.twitterLinkURL.description'
          ),
          variable: 'twitter_link',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.basicSettings.helplineNumber.title'
          ),
          placeholder: `e.g +8801700000000`,
          description: this.$t(
            'components.settingsManagement.riderApp.basicSettings.helplineNumber.description'
          ),
          variable: 'helpline',
          isEdit: false,
        },
      ],
      appInstructionSettings: {
        0: {
          id: 0,
          title: this.$t(
            'components.settingsManagement.riderApp.unlockScreenIllustration.serviceAreaIntro.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.unlockScreenIllustration.serviceAreaIntro.description'
          ),
          should_display: true,
        },
        1: {
          id: 1,
          title: this.$t(
            'components.settingsManagement.riderApp.unlockScreenIllustration.restrictedAreaIntro.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.unlockScreenIllustration.restrictedAreaIntro.description'
          ),
          should_display: true,
        },
        2: {
          id: 2,
          title: this.$t(
            'components.settingsManagement.riderApp.unlockScreenIllustration.slowzoneAreaIntro.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.unlockScreenIllustration.slowzoneAreaIntro.description'
          ),
          should_display: true,
        },
        3: {
          id: 3,
          title: this.$t(
            'components.settingsManagement.riderApp.unlockScreenIllustration.parkingAreaIntro.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.unlockScreenIllustration.parkingAreaIntro.description'
          ),
          should_display: true,
        },
        4: {
          id: 4,
          title: this.$t(
            'components.settingsManagement.riderApp.unlockScreenIllustration.preAuthorizationIntro.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.unlockScreenIllustration.preAuthorizationIntro.description'
          ),
          should_display: true,
        },
        5: {
          id: 5,
          title: this.$t(
            'components.settingsManagement.riderApp.unlockScreenIllustration.rewardZoneIntro.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.unlockScreenIllustration.rewardZoneIntro.description'
          ),
          should_display: true,
        },
      },
      popupScreenBeforeUnlockingVehicleSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.popupScreenBeforeUnlockingVehicle.rentPackages.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.popupScreenBeforeUnlockingVehicle.rentPackages.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_display_trip_packages_before_unlocking_vehicle',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.popupScreenBeforeUnlockingVehicle.paymentMethodSelection.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.popupScreenBeforeUnlockingVehicle.paymentMethodSelection.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_display_payment_source_selection_option',
          isEdit: false,
        },
      ],
      popupScreenSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.popupScreen.paymentMethodSelection.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.popupScreen.paymentMethodSelection.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_display_payment_source_selection_option',
          isEdit: false,
        },
      ],

      appFeatureSettings: [
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.defaultSelectedHomeScreenTab.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.defaultSelectedHomeScreenTab.description'
          ),

          variable: 'default_home_screen',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowRent.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowRent.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'vehicle_renting_enabled',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowPass.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowPass.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'pass_enabled',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowVoucher.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowVoucher.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'voucher_enabled',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowPoints.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowPoints.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'points_enabled',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowLiveChat.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowLiveChat.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'live_chat_enabled',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.mandatoryPhoneNumberAuthentication.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.mandatoryPhoneNumberAuthentication.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_phone_number_authentication_required',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowPhoneNumberVerification.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowPhoneNumberVerification.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'phone_number_verification_required',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowSunpodManagement.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.allowSunpodManagement.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'sun_pod_feature_enabled',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.mandatoryCardForTrips.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.mandatoryCardForTrips.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_card_required_for_trip',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.enableRefundRequestOptionInRiderApp.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.enableRefundRequestOptionInRiderApp.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_rider_allowed_to_apply_for_balance_refund',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.unitForCO2Saved.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.unitForCO2Saved.description'
          ),

          variable: 'co2_saved_unit',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.unitForWeight.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appFeatureSettings.unitForWeight.description'
          ),

          variable: 'weight_unit',
          isEdit: false,
        },
      ],
      appWalletSettings: [
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.riderApp.appWalletSettings.riderWalletBalanceType.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appWalletSettings.riderWalletBalanceType.description'
          ),

          variable: 'rider_wallet_balance_type',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.riderApp.appWalletSettings.shouldRiderBalanceExpire.title'
          ),
          description: this.$t(
            'components.settingsManagement.riderApp.appWalletSettings.shouldRiderBalanceExpire.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_rider_balance_expire',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.riderApp.appWalletSettings.riderBalanceValidityPeriod.title'
          ),
          placeholder: `5`,
          description: this.$t(
            'components.settingsManagement.riderApp.appWalletSettings.riderBalanceValidityPeriod.description'
          ),
          variable: 'rider_balance_validity_period',
          haveUnitText: true,
          unitText: 'Daily',
          isEdit: false,
        },
      ],
      additionalMessageListHeaders: [
        {
          text: this.$t(
            'components.settingsManagement.riderApp.additionalMessages.list.headers.type'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.settingsManagement.riderApp.additionalMessages.list.headers.message'
          ),
          width: '70%',
          sort: null,
        },
        {
          text: this.$t(
            'components.settingsManagement.riderApp.additionalMessages.list.headers.action'
          ),
          width: '10%',
          sort: null,
        },
      ],
      additionalMessageList: [],
      additionalMessageTypes: [],
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    getCO2SavedUnitChoices() {
      return this.co2SavedUnitChoices
    },
    getRiderWalletBalanceTypeChoices() {
      return this.riderWalletBalanceTypeChoices
    },
    getRiderWeightUnitChoices() {
      return this.riderWeightUnitChoices
    },
    getDefaultHomeScreenChoices() {
      return this.defaultHomeScreenChoices
    },
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'basic') {
          this.appFeatureSettings = this.appFeatureSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.pushNotificationSettings = this.pushNotificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
        }
        if (val === 'appFeature') {
          this.basicSettings = this.basicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.pushNotificationSettings = this.pushNotificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
        }
        if (val === 'pushNotification') {
          this.basicSettings = this.basicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.appFeatureSettings = this.appFeatureSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'riderApp') {
          this.appFeatureSettings = this.appFeatureSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.basicSettings = this.basicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.pushNotificationSettings = this.pushNotificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
        }
      },
    },
  },
  async created() {
    const riderAppInfoReq = this.$http.get(
      useEndpoints.settings.riderApp.index(this.orgId)
    )

    const co2SavedUnitChoicesReq = this.$http.get(
      useEndpoints.settings.riderApp.co2SavedUnitChoices(this.orgId)
    )

    const riderWeightUnitChoicesReq = this.$http.get(
      useEndpoints.settings.riderApp.riderWeightUnitChoices(this.orgId)
    )

    const appInstructionSettingsReq = this.$http.get(
      useEndpoints.settings.riderApp.appInstructionSettings()
    )

    const basicSettingsReq = this.$http.get(
      useEndpoints.settings.basic.index(this.orgId)
    )

    const additionalMessageSettingsReq = this.$http.get(
      useEndpoints.organization.additionalMessageSettings.index()
    )
    const additionalMessageTypeChoicesReq = this.$http.get(
      useEndpoints.organization.additionalMessageSettings.choices()
    )

    const requests = [
      riderAppInfoReq,
      co2SavedUnitChoicesReq,
      riderWeightUnitChoicesReq,
      appInstructionSettingsReq,
      basicSettingsReq,
      additionalMessageSettingsReq,
      additionalMessageTypeChoicesReq,
    ]

    try {
      const responses = await Promise.allSettled(requests)
      responses.map((response) => {
        if (response.status === 'fulfilled') {
          if (
            response.value.config.url ===
            useEndpoints.settings.riderApp.index(this.orgId)
          ) {
            const riderAppRes = response.value
            const formatted = {}
            for (const k in riderAppRes.data) {
              formatted[k] =
                riderAppRes.data[k] === null ? '' : riderAppRes.data[k]
            }
            this.riderApp = { ...formatted }
          }
          if (
            response.value.config.url ===
            useEndpoints.settings.riderApp.co2SavedUnitChoices(this.orgId)
          ) {
            this.co2SavedUnitChoices = response.value.data
          }
          if (
            response.value.config.url ===
            useEndpoints.settings.riderApp.riderWeightUnitChoices(this.orgId)
          ) {
            this.riderWeightUnitChoices = response.value.data
          }
          if (
            response.value.config.url ===
            useEndpoints.settings.riderApp.appInstructionSettings()
          ) {
            response.value.data.data.map((item) => {
              this.appInstructionSettings[item.type].id = item.id
              this.appInstructionSettings[item.type].should_display =
                item.should_display
            })
          }
          if (
            response.value.config.url ===
            useEndpoints.settings.basic.index(this.orgId)
          ) {
            this.basic.privacy_policy_url =
              response.value.data.privacy_policy_url
            this.basic.terms_and_conditions_url =
              response.value.data.terms_and_conditions_url
            this.basic.terms_of_service_url =
              response.value.data.terms_of_service_url
            this.basic.faq_url = response.value.data.faq_url
            this.basic.about_url = response.value.data.about_url
            this.basic.help_url = response.value.data.help_url
            this.basic.facebook_link = response.value.data.facebook_link
            this.basic.instagram_link = response.value.data.instagram_link
            this.basic.twitter_link = response.value.data.twitter_link
            this.basic.helpline = response.value.data.helpline
          }
          if (
            response.value.config.url ===
            useEndpoints.organization.additionalMessageSettings.index()
          ) {
            this.additionalMessageList = response.value.data
          }
          if (
            response.value.config.url ===
            useEndpoints.organization.additionalMessageSettings.choices()
          ) {
            this.additionalMessageTypes = response.value.data
          }
        }
        if (response.status === 'rejected') {
          let message = ''
          if (
            response.reason.config.url ===
            useEndpoints.settings.riderApp.index(this.orgId)
          ) {
            message = `App Settings: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            useEndpoints.settings.riderApp.co2SavedUnitChoices(this.orgId)
          ) {
            message = `CO2 Choices: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            useEndpoints.settings.riderApp.riderWeightUnitChoices(this.orgId)
          ) {
            message = `Rider Weight Unit Choices: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            useEndpoints.settings.riderApp.appInstructionSettings()
          ) {
            message = `App Instruction Settings: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            useEndpoints.settings.basic.index(this.orgId)
          ) {
            message = `Basic Settings: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            useEndpoints.organization.additionalMessageSettings.index()
          ) {
            message = `Message Settings: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            useEndpoints.organization.additionalMessageSettings.choices()
          ) {
            message = `Message Choices: ${response.reason.response.data.detail}`
          }

          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: `Error [${response.reason.response.status}]`,
              text: message,
            },
            5000
          )
        }
      })
    } catch (err) {
      console.log('Error loading data', { err })
    } finally {
      this.isLoaded = true
    }
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'basic') {
        this.basicSettings = this.basicSettings.map((item, itemIndex) => {
          if (id === `basic_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'basic'
      }
      if (idPrefix === 'appFeature') {
        this.appFeatureSettings = this.appFeatureSettings.map(
          (item, itemIndex) => {
            if (id === `appFeature_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'appFeature'
      }

      if (idPrefix === 'pushNotification') {
        this.pushNotificationSettings = this.pushNotificationSettings.map(
          (item, itemIndex) => {
            if (id === `pushNotification_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'pushNotification'
      }
    })
    window.addEventListener(HardwareMessageConfig.events.refresh, () => {
      this.fetchHardwareMessage()
    })
  },
  methods: {
    async onSave(apiGroup, key, val) {
      let toastTitle, toastText

      if (key === 'privacy_policy_url') {
        toastTitle = `Privacy Policy URL`
        toastText = `Privacy Policy URL is updated`
      }
      if (key === 'terms_of_service_url') {
        toastTitle = `Terms of Service URL`
        toastText = `Terms of Service URL is updated`
      }
      if (key === 'terms_and_conditions_url') {
        toastTitle = `Terms & Conditions URL`
        toastText = `Terms & Conditions URL is updated`
      }
      if (key === 'about_url') {
        toastTitle = `About Link`
        toastText = `About Link is updated`
      }
      if (key === 'faq_url') {
        toastTitle = `F.A.Q. Link`
        toastText = `F.A.Q. Link is updated`
      }
      if (key === 'help_url') {
        toastTitle = `Help Link`
        toastText = `Help Link is updated`
      }
      if (key === 'facebook_link') {
        toastTitle = `Facebook Link`
        toastText = `Facebook Link is updated`
      }
      if (key === 'instagram_link') {
        toastTitle = `Instagram Link`
        toastText = `Instagram Link is updated`
      }
      if (key === 'twitter_link') {
        toastTitle = `Twitter Link`
        toastText = `Twitter Link is updated`
      }
      if (key === 'helpline') {
        toastTitle = `Helpline`
        toastText = `Helpline is updated`
      }

      if (key === 'app_latest_version') {
        toastTitle = `App Latest Version`
        toastText = `App Latest Version has been updated`
      }
      if (key === 'app_min_version') {
        toastTitle = `Required Min. Version`
        toastText = `Required Min. Version has been updated`
      }
      if (key === 'soft_update_title') {
        toastTitle = `Soft Update Title`
        toastText = `Soft Update Title has been updated`
      }
      if (key === 'soft_update_description') {
        toastTitle = `Soft Update Description`
        toastText = `Soft Update Description has been updated`
      }
      if (key === 'hard_update_title') {
        toastTitle = `Hard Update Title`
        toastText = `Hard Update Title has been updated`
      }
      if (key === 'hard_update_description') {
        toastTitle = `Hard Update Description`
        toastText = `Hard Update Description has been updated`
      }
      if (key === 'vehicle_renting_enabled') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'is_card_required_for_trip') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'is_rider_allowed_to_apply_for_balance_refund') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'pass_enabled') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'voucher_enabled') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'points_enabled') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'live_chat_enabled') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'is_phone_number_authentication_required') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'phone_number_verification_required') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'co2_saved_unit') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'weight_unit') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'should_display_payment_source_selection_option') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'fcm_server_key') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'fcm_project_id') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'fcm_private_key_id') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'fcm_private_key') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'fcm_client_email') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'fcm_client_id') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'fcm_client_x509_cert_url') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'sun_pod_feature_enabled') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'should_rider_balance_expire') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'rider_balance_validity_period') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'rider_wallet_balance_type') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'default_home_screen') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }
      if (key === 'should_display_trip_packages_before_unlocking_vehicle') {
        toastTitle = `Success`
        toastText = `Updated Successfully`
      }

      let url = ''
      if (apiGroup === 'basic') {
        url = useEndpoints.settings.basic.index(this.orgId)
      }
      if (apiGroup === 'riderApp') {
        url = useEndpoints.settings.riderApp.index(this.orgId)
      }
      let data = new FormData()
      data.append(key, val)

      await this.$http
        .patch(url, data)
        .then((res) => {
          console.log(res)

          this.basicSettings = this.basicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.appFeatureSettings = this.appFeatureSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.pushNotificationSettings = this.pushNotificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          this.counter++
        })
        .catch((err) => {
          console.log('onSaveError', { err })

          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Error',
              text: err.response.data.message || 'Failed to update settings',
            },
            5000
          )
        })
    },
    onInstructionSave(id, value) {
      this.basicSettings = this.basicSettings.map((item) => ({
        ...item,
        isEdit: false,
      }))

      this.appFeatureSettings = this.appFeatureSettings.map((item) => ({
        ...item,
        isEdit: false,
      }))

      console.log('onInstructionSave', id, value)
      const url = useEndpoints.settings.riderApp.updateAppInstructionSettings(
        id
      )
      const formData = new FormData()
      formData.append('should_display', value)
      this.$http
        .patch(url, formData)
        .then(() => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Update Successfully',
            },
            3000
          )
        })
        .catch((err) => {
          console.log('appInstructionErr', { err })
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
    fetchHardwareMessage() {
      this.$http
        .get(useEndpoints.organization.additionalMessageSettings.index())
        .then((res) => {
          this.additionalMessageList = res.data
        })
    },

    onAddHardwareMessage() {
      EventBus.$emit(HardwareMessageConfig.events.editingData, {})
      dispatchEvent(new Event(HardwareMessageConfig.events.sorToggle))
    },
    onEditHardwareMessage(item) {
      EventBus.$emit(HardwareMessageConfig.events.editingData, item)
      dispatchEvent(new Event(HardwareMessageConfig.events.sorToggle))
    },
    async onRemoveHardwareMessage(itemId) {
      const url = useEndpoints.organization.additionalMessageSettings.delete(
        itemId
      )
      await this.$http
        .delete(url)
        .then(() => {
          this.fetchHardwareMessage()
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Delete Successfully',
            },
            3000
          )
        })
        .catch((err) => {
          // todo: reverse toggle state
          console.log('h-message-err', { err })
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: `Failed [${err.response.status}]`,
              text: 'Failed to delete',
            },
            3000
          )
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.pencil-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.title-description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63636c !important;
}
</style>
